import { Chip, IconButton, Stack } from '@mui/material';
import { AddDealInput } from 'api';
import { DealPortEditorContent } from 'components/DealPortEditorContent';
import { useWatch, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TXT_LOADING_PORT, TXT_READY_TO_SYNC } from '../../../../../shared/translations';
import DeleteIcon from '@mui/icons-material/Delete';
import { useContext } from 'react';
import { DealWizardContext } from './DealWizard';
import capitalizeEveryWord from '../../../../../shared/stringUtils/capitalizeEveryWord';

interface DealWizardLoadingPortProps {
  index: number;
  handleRemove: (index: number) => void;
}

export const DealWizardLoadingPort = (props: DealWizardLoadingPortProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<AddDealInput>();
  const { isDisabled } = useContext(DealWizardContext);

  const watchLoadingPort = useWatch({
    control: control,
    name: `loadingPorts.${props.index}`
  });

  return (
    <fieldset>
      <legend>{watchLoadingPort.port?.name || `${t(TXT_LOADING_PORT).toUpperCase()} #${props.index + 1}`}</legend>
      <Stack
        spacing={2}
        direction="row"
        sx={(theme) => ({
          mb: theme.spacing(1),
          width: '100%'
        })}
        justifyContent="flex-end"
        alignItems="center"
      >
        {!!watchLoadingPort.pendingToSync ? (
          <Chip label={capitalizeEveryWord(t(TXT_READY_TO_SYNC))} color="success" variant="outlined" />
        ) : null}
        <IconButton
          aria-label={`delete-loading-port-${props.index}`}
          onClick={() => props.handleRemove(props.index)}
          disabled={isDisabled}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
      <DealPortEditorContent isLoadingPort index={props.index} />
    </fieldset>
  );
};
