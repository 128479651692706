import { gql } from '@apollo/client';
import { dealModel } from '../../../model';
import { laytimeCalculationFragment } from '../laytimeCalculation/laytimeCalculationMutations';
import { basePagingResult } from '../base';

const getCalculationCounts = `
  calculationCounts {
    draft
    negotiating
    agreed
    processed
    notApplicable
  }
`;

const getDealPortFragment = `
  id
  portId
  port {
    id
    name
    country
  },
  quantity,
  billOfLadingDate,
  validNor
  laycanStartDate
  laycanEndDate
  norTenderedDate
  commencedDate
  completedDate
  agentId
  agent {
    id
    name
  }
  pendingToSync
`;

const getBaseDealFragment = `
  id
  name
  isActive
  isQuote
  parentLaytimeCalculationId
  shipownerId
  shipowner {
    id
    name
  }
  vesselId
  vessel {
    id
    name
    vesselType
  }
  loadingPorts {
    ${getDealPortFragment}
  }
  unloadingPorts {
    ${getDealPortFragment}
  }
  currency
  massUnit
  splitQuantities {
    id
    quantity
    incoTerm
    counterPartyId
    counterParty {
      id
      name
      businessUnitId
    }
    unloadingPortId
    unloadingPort {
      id
      portId
      port {
        id
        name
      }
    }
    loadingPortId
    loadingPort {
      id
      portId
      port {
        id
        name
      }
    }
    productId
    product {
      id
      name
    }
    traderId
    trader {
      id
      name
      initials
    }
    businessUnitId
    businessUnit {
      id
      name
      initials
    }
    trafficSpecialistId
    trafficSpecialist {
      id
      profile{
        id
        initial
        fullName
      }
    }
    name
    parentSplitQuantityName
    canDelete
  }
  suppliers {
    id
    supplierId
    supplier {
      id
      name
    }
    quantity
    incoTerm
    dealId
    canDelete
    applicableDealPortId
    applicableDealPort {
      id
      portId
      port {
        id
        name
      }
    }
  }
`;

export const getDealFragment = `
  ${getBaseDealFragment}
  loadingCalculations {
    ${laytimeCalculationFragment}
  }
  unloadingCalculations {
    ${laytimeCalculationFragment}
  }
  parentDealId
`;

export const getDealsFragment = `
  items {
    ${getBaseDealFragment}
    ${getCalculationCounts}
  }
  totalCount
`;

export type GetDealsOutput = {
  deals: basePagingResult<dealModel>;
};

export type GetDealOutput = {
  deal?: dealModel;
};

export const GET_DEALS = gql`
  query getDeals($take: Int!, $skip: Int!, $where: DealFilterInput) {
    deals(take: $take, skip: $skip, where: $where) {
      ${getDealsFragment}
    }
  }
`;

export const GET_DEAL = gql`
  query getDeal($dealId: UUID!) {
    deal(dealId: $dealId) {
      ${getDealFragment}
    }
  }
`;

export interface GetQuickCalcCount {
  quickCalcCount: number;
}

export const GET_QUICK_CALC_COUNT = gql`
  query getQuickCalcCount($parentLaytimeCalculationId: UUID!) {
    quickCalcCount(parentLaytimeCalculationId: $parentLaytimeCalculationId)
  }
`;
