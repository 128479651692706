import * as constants from "./translationConstants";

export const enUS = {
  [constants.TXT_ACTIVE]: "active",
  [constants.TXT_ADD]: "add",
  [constants.TXT_OPTIONAL]: "optional",
  [constants.TXT_EDIT_INTERRUPTIONS]: "edit interruptions",
  [constants.TXT_CALENDAR_RULES]: "calendar rules",
  [constants.TXT_SELECTED_COUNTERPARTIES]: "selected counterparties",
  [constants.TXT_INCLUDE_ALL_PARTIES]: "Include all counterparties present in a deal",
  [constants.TXT_ADD_COUNTERPARTIES_MESSAGE]: "add one or more counterparties",
  [constants.TXT_ADMIN]: "administrator",
  [constants.TXT_AGREED]: "agreed",
  [constants.TXT_ALL]: "all",
  [constants.TXT_ALIAS]: "alias",
  [constants.TXT_API_KEY]: "aPI key",
  [constants.TXT_APPLICABLE_PERCENTAGE]: "applicable percentage",
  [constants.TXT_TO_DISCOUNT]: "to discount",
  [constants.TXT_BILL_OF_LADING_DATE]: "bill of lading",
  [constants.TXT_BL]: "bl",
  [constants.TXT_BL_QUANTITY]: "BL quantity",
  [constants.TXT_BUSINESS_UNIT]: "business unit",
  [constants.TXT_INTERNAL_DEAL]: "internal deal",
  [constants.TXT_BUSINESS_UNITS]: "business units",
  [constants.TXT_CALCULATE_AGAINST]: "calculate against",
  [constants.TXT_CALCULATION_RESULTS]: "calculation results",
  [constants.TXT_CALCULATIONS]: "calculations",
  [constants.TXT_CANCEL]: "cancel",
  [constants.TXT_CREATE_ANOTHER]: "create another",
  [constants.TXT_CLEAR]: "clear",
  [constants.TXT_CLIENTS]: "clients",
  [constants.TXT_CLIENT]: "client",
  [constants.TXT_CLOSED]: "closed",
  [constants.TXT_COMMENCED]: "commenced",
  [constants.TXT_COMPLETED]: "completed",
  [constants.TXT_CONFIGURATIONS]: "configurations",
  [constants.TXT_COUNTERPARTIES]: "counterparties",
  [constants.TXT_COUNTERPARTY]: "counterparty",
  [constants.TXT_SHIPOWNER_NOT_SET]: "Shipowner has not been set",
  [constants.TXT_COUNTERPARTY_TYPE]: "counterparty type",
  [constants.TXT_COUNTERPARTY_TYPES]: "counterparty types",
  [constants.TXT_CONNECT_TO_MICROSOFT_ACCOUNT]: "connect to microsoft account",
  [constants.TXT_COPY]: "copy",
  [constants.TXT_COUNT_AS_LAYTIME]: "count as laytime",
  [constants.TXT_CREATE_API_KEY]: "create an aPI key",
  [constants.TXT_CREATE_BUSINESS_UNIT]: "create a business unit",
  [constants.TXT_CREATE_COUNTERPARTY]: "create a counterparty",
  [constants.TXT_OVERRIDE_LAYTIME_CALC_FINAL_VALUE]:
    "override calculation result",
  [constants.TXT_OVERRIDE_LAYTIME_COMMENCED]: "override laytime commenced",
  [constants.TXT_UNDO_OVERRIDE_LAYTIME_CALC_FINAL_VALUE]:
    "Undo override of calculation result",
  [constants.TXT_CREATE_DEAL]: "create a deal",
  [constants.TXT_CREATE_QUICK_CALCULATION]: "create a quick calculation",
  [constants.TXT_CREATE_VESSEL]: "create a vessel",
  [constants.TXT_CREATE_TRADER]: "create a trader",
  [constants.TXT_CURRENCY]: "currency",
  [constants.TXT_DARK_THEME]: "dark theme",
  [constants.TXT_DASHBOARD]: "dashboard",
  [constants.TXT_DAY]: "day",
  [constants.TXT_DEALS]: "deals",
  [constants.TXT_QUICK_CALCS]: "quick calculations",
  [constants.TXT_QUICK_CALC]: "quick calculation",
  [constants.TXT_DEAL_NUMBER]: "deal number",
  [constants.TXT_PARENT_NUMBER]: "parent deal number",
  [constants.TXT_TXT_VOYAGE_NAME]: "voyage name",
  [constants.TXT_DEAL_INFO]: "deal info",
  [constants.TXT_DESPATCH_DECIMAL_EQUIVALENT]: "despatch decimal equivalent",
  [constants.TXT_IGNORE]: "ignore",
  [constants.TXT_DELETE]: "delete",
  [constants.TXT_DELETE_CONFIRMATION]: "delete confirmation",
  [constants.TXT_DELETE_TEXT]: 'are you sure you want to delete "{{name}}"?',
  [constants.TXT_CONFIRM_PASTE_PARAMS_TEXT]:
    "please confirm the info you want to Copy ?",
  [constants.TXT_LAYTIME]: "laytime",
  [constants.TXT_DEMURRAGE]: "demurrage",
  [constants.TXT_DEMURRAGE_DECIMAL_EQUIVALENT]: "demurrage decimal equivalent",
  [constants.TXT_DEMURRAGE_P_DAY]: "demurrage p/day",
  [constants.TXT_DEMURRAGE_IN_CURRENCY]: "demurrage final value",
  [constants.TXT_DESCRIPTION]: "description",
  [constants.TXT_REMARKS]: "remarks",
  [constants.TXT_ERRORS]: "errors",
  [constants.TXT_DESPATCH]: "despatch",
  [constants.TXT_DESPATCH_IN_CURRENCY]: "despatch final value",
  [constants.TXT_DESPATCH_P_DAY]: "despatch p/day",
  [constants.TXT_DISABLE]: "disable",
  [constants.TXT_DOCUMENT_USED]: "document used",
  [constants.TXT_DRAFT]: "draft",
  [constants.TXT_EDIT]: "edit",
  [constants.TXT_EMAILS]: "emails",
  [constants.TXT_EMAIL]: "email",
  [constants.TXT_ENABLE]: "enable",
  [constants.TXT_VALID_NOR]: "valid nor",
  [constants.TXT_ALWAYS_ON_DEMURRAGE]: "always on demurrage",
  [constants.TXT_NOT_ALWAYS_ON_DEMURRAGE]: "not always on demurrage",
  [constants.TXT_EVEN_IF_SOONER_COMMENCED]: "even if sooner commenced",
  [constants.TXT_FIELD_IS_REQUIRED]: "this field is required",
  [constants.TXT_FREIGHT_PROVIDER]: "freight provider",
  [constants.TXT_FULL_NAME]: "full name",
  [constants.TXT_GENERATING_REPORT]: "generating report",
  [constants.TXT_GOOGLE_ACCOUNT]: "google account",
  [constants.TXT_GO_TO_SIGN_IN_PAGE]: "go to sign in page",
  [constants.TXT_INITIAL]: "initials",
  [constants.TXT_REFERENCE]: "reference",
  [constants.TXT_INTEGRATIONS]: "integrations",
  [constants.TXT_INTERRUPTIONS]: "interruptions",
  [constants.TXT_INTERRUPTION_DEFINITIONS]: "interruption definitions",
  [constants.TXT_INTERRUPTION_DEFINITION]: "interruption definition",
  [constants.TXT_INVITATION_NOT_FOUND]:
    "the invitation link has been expired or invalid",
  [constants.TXT_INVITATION_SENT]: "the invitation has been sent to {{email}}",
  [constants.TXT_INVITE]: "invite",
  [constants.TXT_INVITE_NEW_USERS]: "invite new users",
  [constants.TXT_INVITE_USERS]: "invite users",
  [constants.TXT_INVITING]: "inviting",
  [constants.TXT_INVOICED]: "invoiced",
  [constants.TXT_LAYCAN]: "laycan",
  [constants.TXT_LAYTIME_CALCULATION]: "laytime calculation",
  [constants.TXT_LAYTIME_CHART]: "laytime chart",
  [constants.TXT_LAYTIME_PER_COUNTERPARTY]: "laytime per counterparty",
  [constants.TXT_SUPPLIER_VS_OWNER_REPORT]: "supplier vs owner",
  [constants.TXT_LAYCAN_END]: "laycan end",
  [constants.TXT_LAYCAN_START]: "laycan start",
  [constants.TXT_LESS_INTERRUPTIONS]: "less interruptions",
  [constants.TXT_LIGHT_THEME]: "light theme",
  [constants.TXT_LOADING]: "loading",
  [constants.TXT_UNLOADING]: "unloading",
  [constants.TXT_CHILD_DEAL]: "child deal",
  [constants.TXT_CHILD_DEALS]: "child deals",
  [constants.TXT_VESSEL_OUTSIDE_LAYCAN]: "Vessel is outside of laycan",
  [constants.TXT_LOADING_PORT]: "loading port",
  [constants.TXT_LOADING_PORTS]: "loading ports",
  [constants.TXT_LOADING_COMMENCED]: "loading commenced",
  [constants.TXT_LOADING_COMPLETED]: "loading completed",
  [constants.TXT_DISCHARGE_COMMENCED]: "discharge commenced",
  [constants.TXT_DISCHARGE_COMPLETED]: "discharge completed",
  [constants.TXT_LOADING_RATE]: "loading rate",
  [constants.TXT_DISCHARGE_RATE]: "discharge rate",
  [constants.TXT_MICROSOFT_ACCOUNT]: "microsoft account",
  [constants.TXT_MASS_UNIT]: "mass unit",
  [constants.TXT_MV_ON_DEMURRAGE]: "vessel on demurrage",
  [constants.TXT_NAME]: "name",
  [constants.TXT_NEGOTIATING]: "negotiating",
  [constants.TXT_NOT_APPLICABLE]: "not applicable",
  [constants.TXT_CODE]: "code",
  [constants.TXT_CAPACITY]: "capacity",
  [constants.TXT_NET_TIME_USED]: "net time used",
  [constants.TXT_NOT_ACCEPTED]: "not accepted",
  [constants.TXT_NOT_COUNT_AS_LAYTIME]: "not count as laytime",
  [constants.TXT_NO]: "no",
  [constants.TXT_NOR_TENDERED]: "nor tendered",
  [constants.TXT_NO_DATA_FOUND]: "no data found",
  [constants.TXT_OR]: "or",
  [constants.TXT_PASSWORD]: "password",
  [constants.TXT_PASTE]: "paste",
  [constants.TXT_PERMITTED_TIME]: "allowed time",
  [constants.TXT_PORT]: "port",
  [constants.TXT_PRODUCT]: "product",
  [constants.TXT_PROCESSED]: "processed",
  [constants.TXT_QUANTITY]: "quantity",
  [constants.TXT_SHARED_PERCENTAGE]: "shared percentage",
  [constants.TXT_REAL_LT_BEGINS]: "laytime begins",
  [constants.TXT_LAYTIME_ENDS]: "laytime ends",
  [constants.TXT_PERMITTED_TIME_ROUNDING]: "permitted time rounding",
  [constants.TXT_SUPPLIER_CONTRIBUTION]: "supplier contribution",
  [constants.TXT_SUPPLIER_CONTRIBUTION_ERROR]:
    "supplier’s conttibution does not add to 100%",
  [constants.TXT_MODIFYING_LOCKED_CALC_VALIDATION]:
    "The value of this calculation is locked! The changes were saved but the final value did not change.",
  [constants.TXT_RECEIVERS]: "receivers",
  [constants.TXT_RECEIVER]: "receiver",
  [constants.TXT_REGISTERING]: "registering",
  [constants.TXT_REGISTER]: "register",
  [constants.TXT_REGISTER_A_NEW_TENANT]: "register a new tenant",
  [constants.TXT_REGISTER_THE_FIRST_USER]: "register the first user",
  [constants.TXT_REJECTED]: "rejected",
  [constants.TXT_RETYPE_PASSWORD]: "re-type password",
  [constants.TXT_REPORTS]: "reports",
  [constants.TXT_ROWS_PER_PAGE]: "rows per page",
  [constants.TXT_SALES_CONTRACT]: "sales contract",
  [constants.TXT_SAVE]: "save",
  [constants.TXT_SAVING]: "saving",
  [constants.TXT_SAVE_AND_CLOSE]: "save & close",
  [constants.TXT_INTERRUPTION_TOO_BIG]: "time span has 48 hours or more",
  [constants.TXT_INTERRUPTION_HAS_DATES_IN_FUTURE]:
    "dates in the future are not allowed",
  [constants.TXT_SEARCH]: "search",
  [constants.TXT_SEARCH_BY_NAME]: "search by name",
  [constants.TXT_SEARCH_BY_USERNAME_NAME_INITIAL]:
    "search by username or name or initial",
  [constants.TXT_DEAL]: "deal",
  [constants.TXT_SHIPOWNER]: "shipowner",
  [constants.TXT_SIGNING_IN]: "signing in",
  [constants.TXT_SIGNING_OUT]: "signing out",
  [constants.TXT_SIGN_IN]: "sign in",
  [constants.TXT_SIGN_OUT]: "sign out",
  [constants.TXT_SINCE]: "since",
  [constants.TXT_STATUS]: "status",
  [constants.TXT_STATUS_COMMENT]: "status comment",
  [constants.TXT_SUBMIT]: "submit",
  [constants.TXT_SUPPLIER]: "supplier",
  [constants.TXT_SUPPLIERS]: "suppliers",
  [constants.TXT_SPECIAL_CONDITION]: "special condition",
  [constants.TXT_ONCEONDEMURRAGE_CONDITION]: "once on demurrage...",
  [constants.TXT_TERM]: "term",
  [constants.TXT_TIME_IN_DESPATCH]: "time in despatch",
  [constants.TXT_TIME_IN_DEMURRAGE]: "time in demurrage",
  [constants.TXT_TOTAL_TIME_USED]: "total time used",
  [constants.TXT_TRAFFIC_ANALYST]: "traffic analyst",
  [constants.TXT_TO]: "to",
  [constants.TXT_UNTIL]: "until",
  [constants.TXT_FROM]: "from",
  [constants.TXT_TURN_TIME]: "turn time",
  [constants.TXT_TYPE]: "type",
  [constants.TXT_VESSEL_TYPE]: "vessel type",
  [constants.TXT_TRADER]: "trader",
  [constants.TXT_TRADERS]: "traders",
  [constants.TXT_UNLOADING_PORT]: "unloading port",
  [constants.TXT_UNLOADING_PORTS]: "unloading ports",
  [constants.TXT_UNLOADING_COMMENCED]: "unloading commenced",
  [constants.TXT_UNLOADING_COMPLETED]: "unloading completed",
  [constants.TXT_UNLESS_SOONER_COMMENCED]: "unless sooner commenced",
  [constants.TXT_UPDATE_BUSINESS_UNIT]: "update business unit",
  [constants.TXT_UPDATE_COUNTERPARTY]: "update counterparty",
  [constants.TXT_BATCH_OVERRIDE_TITLE]: "select the calculations to override",
  [constants.TXT_UPDATE_DEAL]: "update a deal",
  [constants.TXT_UPDATE_QUICK_CALC]: "update a quick calculation",
  [constants.TXT_UPDATE_VESSEL]: "update vessel",
  [constants.TXT_UPDATE_TRADER]: "update trader",
  [constants.TXT_USERS]: "users",
  [constants.TXT_USER]: "user",
  [constants.TXT_VESSELS]: "vessels",
  [constants.TXT_VESSEL]: "vessel",
  [constants.TXT_LAKER]: "laker",
  [constants.TXT_IMO]: 'IMO',
  [constants.TXT_VESSEL_INFORMATION]: "vessel information",
  [constants.TXT_WELCOME]: "{{name}}",
  [constants.TXT_WITH]: "with",
  [constants.TXT_X_TO_DISCOUNT]: "(x) to discount",
  [constants.TXT_YES]: "yes",
  [constants.TXT_PORTS]: "ports",
  [constants.TXT_PRODUCTS]: "products",
  [constants.TXT_MASS_UOM_OVERRIDE]: "UOM override",
  [constants.TXT_COUNTRY]: "country",
  [constants.TXT_CREATE_PORT]: "create a port",
  [constants.TXT_UPDATE_PORT]: "update port",
  [constants.TXT_CREATE_PRODUCT]: "create a product",
  [constants.TXT_UPDATE_PRODUCT]: "update product",
  [constants.TXT_CREATING_LAYTIME_CALCULATION]: "creating laytime calculation",
  [constants.TXT_ADD_LAYTIME_CALCULATION]: "add laytime calculation",
  [constants.TXT_ADD_HOLIDAY]: "add holiday",
  [constants.TXT_OLD_VALUE]: "current value",
  [constants.TXT_NEW_VALUE]: "new value",
  [constants.TXT_PREVIOUS]: "previous",
  [constants.TXT_NEXT]: "next",
  [constants.TXT_VESSEL_INFORMATION_OVERRIDE]: "vessel information override",
  [constants.TXT_FIELD]: "field",
  [constants.TXT_CHANGE_CALCULATION_STATUS]: "change calculation status",
  [constants.TXT_OVERRIDE_ALL_CALCULATIONS]: "override all calculations",
  [constants.TXT_BACK_TO_SIGN_IN_PAGE]: "back to sign in page",
  [constants.TXT_UPDATE_USER]: "update user",
  [constants.TXT_UPDATE_MY_PROFILE]: "update my profile",
  [constants.TXT_SELECTED_ITEMS]: "{{count}} selected",
  [constants.TXT_PORT_TYPE]: "port type",
  [constants.TXT_DATE]: "date",
  [constants.TXT_TIME]: "time",
  [constants.TXT_ADD_INTERRUPTION]: "add interruption",
  [constants.TXT_ADD_RECURRENT_SHEX]: "add recurrent shex",
  [constants.TXT_LINK_HAS_BEEN_COPIED_TO_CLIPBOARD]:
    "the link has been copied to the clipboard",
  [constants.TXT_CLICK_HERE_TO_OPEN]: "Click <0>here</0> to open",
  [constants.TXT_CREATE_SHAREABLELINK]: "create shareable link",
  [constants.TXT_UPDATE_SHAREABLELINK]: "update shareable link",
  [constants.TXT_LINK_ID]: "link id",
  [constants.TXT_DOWNLOAD_LAYTIME_CHART]: "download laytime chart",
  [constants.TXT_DOWNLOAD]: "download",
  [constants.TXT_DOWNLOADING]: "downloading",
  [constants.TXT_LINKED_ACCOUNTS]: "linked accounts",
  [constants.TXT_USER_PROFILE]: "user profile",
  [constants.TXT_CHANGE_PASSWORD]: "change password",
  [constants.TXT_USER_PROFILE_UPDATED_SUCCESSFULLY]:
    "user profile updated successfully",
  [constants.TXT_LAYTIME_CHART_REPORT_FILTER]: "laytime chart report filter",
  [constants.TXT_LAYTIME_PER_COUNTERPARTY_REPORT_FILTER]:
    "laytime per counterparty report filter",
  [constants.TXT_SUPPLIER_VS_OWNER_REPORT_FILTER] : "supplier vs owner report filter",
  [constants.TXT_PROFIT_AND_LOSS]: "profit and loss",
  [constants.TXT_VOYAGE_PROFIT_AND_LOSS]: "voyage profit and loss",
  [constants.TXT_DEAL_PROFIT_AND_LOSS]: "deal profit and loss",
  [constants.TXT_TRAFFIC_SPECIALIST]: "traffic specialist",
  [constants.TXT_SETTLE_STATUS]: "settle status",
  [constants.TXT_NET]: "net",
  [constants.TXT_PROFIT]: "profit",
  [constants.TXT_LOSS]: "loss",
  [constants.TXT_COMPANY]: "company",
  [constants.TXT_DISCHARGE_COUNTRY]: "discharge country",
  [constants.TXT_DISCHARGE_PORT]: "discharge port",
  [constants.TXT_REPORT_COPIED]:
    "report content has been copied to the clipboard",
  [constants.TXT_EXCHANGE_RATE]: "exchange rate",
  [constants.TXT_BARGE]: "barge",
  [constants.TXT_BARGES]: "barges",
  [constants.TXT_HELP]: "help",
  [constants.TXT_CREATE_BARGE]: "create barge",
  [constants.TXT_UPDATE_BARGE]: "update barge",
  [constants.TXT_BARGE_TRIPS]: "barge trips",
  [constants.TXT_CREATE_BARGE_TRIP]: "create barge trip",
  [constants.TXT_UPDATE_BARGE_TRIP]: "update barge trip",
  [constants.TXT_ASSOCIATED_DEAL_NUMBER]: "associated deal number",
  [constants.TXT_DUPLICATING_BARGE]: "duplicating barge...",
  [constants.TXT_ARRIVAL_DATE]: "arrival date",
  [constants.TXT_COMPLETION_DATE]: "completion date",
  [constants.TXT_COMPLETION_DATE_OVERRIDE]: "completion date override",
  [constants.TXT_ARRIVAL_DATE_OVERRIDE]: "arrival date override",
  [constants.TXT_RELEASE_DATE]: "release date",
  [constants.TXT_RELEASE_DATE_OVERRIDE]: "release date override",
  [constants.TXT_AND_MORE]: "and {{count}} more",
  [constants.TXT_FREE_TIME]: "free time",
  [constants.TXT_DAYS]: "days",
  [constants.TXT_HOURS]: "hours",
  [constants.TXT_MINUTES]: "minutes",
  [constants.TXT_TOTAL_DEMURRAGE]: "total demurrage",
  [constants.TXT_OWNER]: "owner",
  [constants.TXT_BARGE_TRIP_INFO]: "barge trip info",
  [constants.TXT_TOTAL]: "total",
  [constants.TXT_CALENDAR_RULE]: "calendar rule",
  [constants.TXT_HOLIDAYS]: "holidays",
  [constants.TXT_CALENDAR]: "calendar",
  [constants.TXT_YEAR]: "year",
  [constants.TXT_EN_US]: "US",
  [constants.TXT_ES_ES]: "Spain",
  [constants.TXT_NO_ROWS]: "no rows",
  [constants.TXT_UPDATE]: "update",
  [constants.TXT_CREATE_HOLIDAY]: "create holiday",
  [constants.TXT_UPDATE_HOLIDAY]: "update holiday",
  [constants.TXT_HOLIDAY]: "holiday",
  [constants.TXT_BARGE_REPORT_FILTER]: "barge report filter",
  [constants.TXT_FORGOT_PASSWORD]: "forgot password",
  [constants.TXT_FORGOT_PASSWORD_TITLE]: "forgot your password?",
  [constants.TXT_FORGOT_PASSWORD_TEXT]:
    "no worries! enter your email address and we will send you the instructions to reset your password",
  [constants.TXT_VERICIATION_CODE_TITLE]: "input verification code",
  [constants.TXT_VERICIATION_CODE]: "verification code",
  [constants.TXT_VERICIATION_CODE_TEXT]:
    "to verify your account, you need to confirm your Atreus account. Please input the verification code that we sent to you",
  [constants.TXT_VERIFY]: "verify",
  [constants.TXT_ENTER_NEW_PASSWORD]:
    "enter new password to access your account",
  [constants.TXT_BARGE_LAYTIME_PER_COUNTERPARTY]:
    "barge laytime per counterparty",
  [constants.TXT_OCR]: "ocr (coming soon)",
  [constants.TXT_UPLOADING]: "uploading",
  [constants.TXT_NUMBER]: "number",
  [constants.TXT_TEXT]: "text",
  [constants.TXT_RESULT]: "result",
  [constants.TXT_DRAG_N_DROP_TEXT]:
    "drag & drop some files here, or click to select files",
  [constants.TXT_FILE_RESTRICTION]: "only image and pdf will be accepted",
  [constants.TXT_EDIT_CURRENT_DEAL]: "edit current deal",
  [constants.TXT_COMMENTS]: "comments",
  [constants.TXT_TYPE_COMMENT_HERE]: "type comment here",
  [constants.TXT_TYPE_REPLY_HERE]: "type reply here",
  [constants.TXT_PRINT]: "print",
  [constants.TXT_RESOLVE]: "resolve",
  [constants.TXT_RESOLVED]: "resolved",
  [constants.TXT_SEND]: "send",
  [constants.TXT_UNRESOLVE]: "unresolve",
  [constants.TXT_DELETE_COMMENT_WITH_REPLIES]:
    "are you sure you want to delete this comment including all replies?",
  [constants.TXT_DELETE_COMMENT]:
    "are you sure you want to delete this comment?",
  [constants.TXT_QUICK_CALC_INFO]: "quick calculation info",
  [constants.TXT_DETAILS]: "details",
  [constants.TXT_CLOSE]: "close",
  [constants.TXT_VERSION]: "version",
  [constants.TXT_BACK_TO_PARENT]: "back to parent",
  [constants.TXT_GO_TO]: "go to",
  [constants.TXT_PROMOTE]: "promote",
  [constants.TXT_RESET]: "reset",
  [constants.TXT_CONFIRMATION]: "confirmation",
  [constants.TXT_PROMOTE_CALCULATION_CONFIRMATION]:
    "are you sure you want to promote this calculation?",
  [constants.TXT_RESET_CALCULATION_CONFIRMATION]:
    "are you sure you want to reset this calculation?",
  [constants.TXT_WHAT_IF_SCENARIOS]: "What-If Scenarios",
  [constants.TXT_CREATE_WHAT_IF_SCENARIOS]: "Create What-If Scenario",
  [constants.TXT_UPDATE_WHAT_IF_SCENARIOS]: "Update What-If Scenario",
  [constants.TXT_OPEN_WHAT_IF_SCENARIO]: "Open What-If Scenario",
  [constants.TXT_MARITIME_DIRECTORIES]: "maritime directories",
  [constants.TXT_CREATE_MARITIME_DIRECTORY]: "create maritime directory",
  [constants.TXT_UPDATE_MARITIME_DIRECTORY]: "update maritime directory",
  [constants.TXT_DELETE_MARITIME_DIRECTORY]:
    "are you sure you want to delete this contact?",
  [constants.TXT_ADDRESSES]: "addresses",
  [constants.TXT_PHONES]: "phones",
  [constants.TXT_ADDRESS]: "address",
  [constants.TXT_PHONE]: "phone",
  [constants.TXT_COPIED_TO_CLIPBOARD]: "copied to clipboard",
  [constants.TXT_FAILED_TO_COPY_TO_CLIPBOARD]:
    "failed to copy to the clipboard",
  [constants.TXT_CONTACT]: "contact",
  [constants.TXT_AGENT]: "agent",
  [constants.TXT_VESSEL_PORTAL]: "vessel portal",
  [constants.TXT_LATITUDE]: "latitude",
  [constants.TXT_LONGITUDE]: "longitude",
  [constants.TXT_SEND_REQUEST_FORM_TO_AGENT]: "send request form to agent",
  [constants.TXT_REQUEST_FORM]: "request form",
  [constants.TXT_UPDATE_PORT_AGENT]: "update port's agent",
  [constants.TXT_PORT_INFORMATION]: "port information",
  [constants.TXT_SAVED]: "saved",
  [constants.TXT_EMAIL_HAS_BEEN_SENT]: "the email has been sent to {{email}}",
  [constants.TXT_SEND_EMAIL]: "send email",
  [constants.TXT_SENDING_EMAIL]: "sending email to {{email}}",
  [constants.TXT_CREATING_SHAREABLELINK]: "creating shareable link",
  [constants.TXT_SUBMITTING]: "submitting",
  [constants.TXT_SUBMITTED]: "submitted",
  [constants.TXT_DEVELOPED_BY]: "developed by",
  [constants.TXT_REQUESTED_BY]: "requested by",
  [constants.TXT_RESPONDED]: "responded",
  [constants.TXT_GRANT_ACCESS]: "grant access",
  [constants.TXT_COPY_LINK]: "copy link",
  [constants.TXT_DELETE_SHAREABLE_LINK]:
    "are you sure you want to delete this link?",
  [constants.TXT_TONNAGE_ON_BOARD]: "tonnage on board",
  [constants.TXT_TONNAGE_PENDING]: "tonnage pending",
  [constants.TXT_EMAIL_ADDRESS]: "email address",
  [constants.TXT_SEARCH_VESSEL]: "Search vessel by name or IMO",
  [constants.TXT_NO_VESSEL_FOUND]: "no vessel found",
  [constants.TXT_NO_DEAL_FOUND]: "no deal found",
  [constants.TXT_SEARCH_DEAL]: "search deal",
  [constants.TXT_COURSE]: "course",
  [constants.TXT_SPEED]: "speed",
  [constants.TXT_DRAUGHT]: "draught",
  [constants.TXT_CALLSIGN]: "callsign",
  [constants.TXT_LENGTH]: "length",
  [constants.TXT_BEAM]: "beam",
  [constants.TXT_CROSSINGS]: "crossings",
  [constants.TXT_DISTANCE]: "distance",
  [constants.TXT_VESSEL_TRACKER]: "vessel tracker",
  [constants.TXT_PORT_INFORMATION_UPDATED]:
    "port {{name}} information has been updated",
  [constants.TXT_ETA_OUTSIDE_LAYCAN]: "ETA is outside of laycan",
  [constants.TXT_STATEMENT_OF_FACT]: 'statement of fact',
  [constants.TXT_DESTINATION]: 'destination',
  [constants.TXT_VALUE]: 'value',
  [constants.TXT_NOW]: 'now',
  [constants.TXT_OK]: 'ok',
  [constants.TXT_OCR_RESULT]: 'ocr result',
  [constants.TXT_OCR_SCAN]: 'ocr scan',
  [constants.TXT_SPLIT]: 'split',
  [constants.TXT_MERGE]: 'merge',
  [constants.TXT_OPERATOR]: 'operator',
  [constants.TXT_GUEST]: 'guest',
  [constants.TXT_ROLE]: 'role',
  [constants.TXT_PLEASE_SELECT_A_VESSEL]: 'please select a vessel',
  [constants.TXT_SEARCH_BY_SHIPMENT_DEAL_VESSEL]: 'Search by shipment name, deal number, vessel name or vessel IMO',
  [constants.TXT_CURRENT]: 'current',
  [constants.TXT_DIFFERENCE]: 'difference',
  [constants.TXT_REFRESH]: 'refresh',
  [constants.TXT_LAST_RESPOND]: 'last respond',
  [constants.TXT_EDIT_VESSEL]: 'edit vessel',
  [constants.TXT_HISTORY]: 'history',
  [constants.TXT_LOAD_MORE]: 'load more',
  [constants.TXT_LAT_LONG_EXAMPLE]: '{{portName}} is located in {{country}} at {{latC}}, {{longC}}. Therefore the latitude is {{lat}} and the longitude is {{long}}.',
  [constants.TXT_UPDATE_PORT_INFO]: 'update port info',
  [constants.TXT_SUBMIT_AND_COMPLETE]: 'submit and complete',
  [constants.TXT_DEAL_PORT_FORM_SUBMIT_AND_COMPLETE_CONFIRMATION]: 'are you sure you want to submit and complete this form?',
  [constants.TXT_DEAL_PORT_FORM_SUBMIT_AND_COMPLETE_WARNING]: 'you will not be able to access this link in the future',
  [constants.TXT_COMPLETE]: 'complete',
  [constants.TXT_SENDING]: 'sending',
  [constants.TXT_MESSAGE]: 'message',
  [constants.TXT_TYPE_MESSAGE_HERE]: 'type message here',
  [constants.TXT_MESSAGES]: 'messages',
  [constants.TXT_MERGE_PORTS]: 'merge ports',
  [constants.TXT_DATA_MAINTENANCE]: 'data maintenance',
  [constants.TXT_SOURCE_PORTS]: 'source ports',
  [constants.TXT_DESTINATION_PORT]: 'destination port',
  [constants.TXT_TEST]: 'test',
  [constants.TXT_APPLY]: 'apply',
  [constants.TXT_LOG]: 'log',
  [constants.TXT_STARTED_AT]: 'started at',
  [constants.TXT_FINISHED_AT]: 'finished at',
  [constants.TXT_DURATION]: 'duration',
  [constants.TXT_WEB_FORMS]: 'web forms',
  [constants.TXT_READY_TO_SYNC]: 'ready to sync',
  [constants.TXT_SHIPMENT]: 'shipment',
  [constants.TXT_LAST_SEARCHED]: 'last searched',
  [constants.TXT_SHOW_ON_MAP]: 'show on map',
  [constants.TXT_CHANGE_COORDINATES_CONFIRMATION]: 'change coordinates confirmation',
  [constants.TXT_CHANGE_COORDINATES_CONFIRMATION_TEXT]: 'do you want to use these coordinates latitude = {{latitude}},  longitude = {{longitude}}?',
  
  [constants.ERR_INVALID_TENANT]: "invalid tenant",
  [constants.ERR_UNKNOWN_ERROR]: "unknown error",
  [constants.ERR_INVALID_SENDER_EMAIL]: "invalid sender email",
  [constants.ERR_INVALID_RECIPIENT_EMAIL]: "invalid recipient email",
  [constants.ERR_TENANT_ALIAS_EXISTED]:
    "tenant with the same alias has existed",
  [constants.ERR_USER_NOT_FOUND]: "user not found",
  [constants.ERR_USER_DISABLED]:
    "this user was disabled, please contact your administrator",
  [constants.ERR_USERNAME_EXISTED]:
    "the username has been taken by another user",
  [constants.ERR_EMAIL_EXISTED]: "the email has been used by another user",
  [constants.ERR_USER_INITIAL_EXISTED]: "initial has been used by another user",
  [constants.ERR_MICROSOFT_ACCOUNT_EXISTED]:
    "this Microsoft account has been linked to another user",
  [constants.ERR_INVALID_EMAILS]:
    "there are one or more invalid email addresses",
  [constants.ERR_WRONG_PASSWORD]: "wrong password",
  [constants.ERR_INVALID_SPLIT_QUANTITY_NAME]: "invalid associated deal number",
  [constants.ERR_IMO_NOT_DEFINED]: "IMO not defined",
  [constants.ERR_PORT_NO_COORDINATES]: "the port cannot be placed on the map due to missing coordinates",
  [constants.ERR_LOADING_PORT_RULES]: "the vessel trip must have at least one port that has a bill of lading date set",
  [constants.ERR_DEAL_NAME_NOT_MATCH_PATTERN]: "the deal's name does not match the pattern, example of correct input {{example}}",
};
