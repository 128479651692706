import { Fragment, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import { GetDealsOutput, GET_DEALS } from '../../api';
import { capitalizeFirstLetterEveryword } from '../../shared/stringFunctions';
import { useTranslation } from 'react-i18next';
import { TXT_TXT_VOYAGE_NAME } from '../../../../../shared/translations';
import { dealModel } from '../../model';
import { useApolloErrorHandler } from '../../hooks/useApolloErrorHandler';
import { useDefaultSettings } from 'hooks';
import _ from 'lodash';

export interface DealSelectorProps {
  deal?: dealModel;
  error?: boolean;
  disabled?: boolean;
  onChanged?: (deal?: dealModel) => void;
}

export const DealSelector = (props: DealSelectorProps) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<dealModel | null>(props.deal || null);
  const [text, setText] = useState<string | undefined>(props.deal?.name);
  const [options, setOptions] = useState<dealModel[]>([]);
  const [defaultSettings] = useDefaultSettings();
  const [pageSize] = useState(defaultSettings.pageSize);
  const [pageNumber] = useState(1);

  const { apolloErrorHandler } = useApolloErrorHandler();

  const getFilter = () => {
    let filter: any[] = [];

    if (!_.isNil(text) && text.length > 0) {
      filter = [
        ...filter,
        {
          or: [
            {
              name: {
                contains: text
              }
            },
            {
              vessel: {
                name: {
                  contains: text
                }
              }
            },
            {
              splitQuantities: {
                some: {
                  name: {
                    contains: text
                  }
                }
              }
            }
          ]
        }
      ];
    }

    if (selected !== null && selected.id) {
      filter = [
        ...filter,
        {
          id: {
            eq: selected.id
          }
        }
      ];
    }

    return {
      and: [
        {
          scope: {
            eq: 'CORE'
          }
        },
        {
          isQuote: {
            eq: false
          }
        },
        {
          or: filter
        }
      ]
    };
  };

  const [getDeals, { loading, refetch }] = useLazyQuery<GetDealsOutput>(GET_DEALS, {
    fetchPolicy: 'network-only',
    variables: {
      take: pageSize,
      skip: pageSize * (pageNumber - 1),
      where: getFilter()
    },
    onCompleted: (data) => setOptions([...(data.deals?.items ?? [])]),
    onError: apolloErrorHandler
  });

  const fetch = () => {
    if (refetch) {
      refetch();
    } else {
      getDeals();
    }
  };

  const onInputChange = (event: object, value: string) => {
    setText(value);
  };

  const onSelectedChange = (event: object, value: dealModel | null) => {
    setSelected(value);
    props.onChanged && props.onChanged(value === null ? undefined : value);
  };

  useEffect(() => {
    fetch();
  }, [text]);

  return (
    <Autocomplete
      id="splitQuantity-selector"
      onChange={onSelectedChange}
      onInputChange={onInputChange}
      value={selected}
      // isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name || ''}
      options={options}
      loading={loading}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={capitalizeFirstLetterEveryword(t(TXT_TXT_VOYAGE_NAME))}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </Fragment>
            )
          }}
          error={props.error}
        />
      )}
      disabled={props.disabled}
    />
  );
};
