import * as constants from "./translationConstants";

export const esES = {
  [constants.TXT_ACTIVE]: "activo",
  [constants.TXT_ADD]: "agregar",
  [constants.TXT_OPTIONAL]: "opcional",
  [constants.TXT_EDIT_INTERRUPTIONS]: "editar interrupción",
  [constants.TXT_CALENDAR_RULES]: "Reglas al Calendario",
  [constants.TXT_SELECTED_COUNTERPARTIES]: "organizaciones selecccionadas",
  [constants.TXT_INCLUDE_ALL_PARTIES]: "Incluir todas las organizaciones presentes en cada deal",
  [constants.TXT_ADD_COUNTERPARTIES_MESSAGE]: "Agregar una o mas organizaciones",
  [constants.TXT_ADMIN]: "administrador",
  [constants.TXT_AGREED]: "acordado",
  [constants.TXT_ALL]: "todos",
  [constants.TXT_ALIAS]: "alias",
  [constants.TXT_API_KEY]: "clave aPI",
  [constants.TXT_APPLICABLE_PERCENTAGE]: "porcentaje aplicable",
  [constants.TXT_TO_DISCOUNT]: "descontar",
  [constants.TXT_BILL_OF_LADING_DATE]: "guía de carga",
  [constants.TXT_BL]: "bl",
  [constants.TXT_BL_QUANTITY]: "cantidad bl",
  [constants.TXT_BUSINESS_UNIT]: "unidad de negocio",
  [constants.TXT_INTERNAL_DEAL]: "envio interno",
  [constants.TXT_BUSINESS_UNITS]: "unidades de negocios",
  [constants.TXT_CALCULATE_AGAINST]: "calcular contra",
  [constants.TXT_CALCULATION_RESULTS]: "resultados de cálculo",
  [constants.TXT_CALCULATIONS]: "cálculos",
  [constants.TXT_CANCEL]: "cancelar",
  [constants.TXT_CREATE_ANOTHER]: "crear otra",
  [constants.TXT_CLEAR]: "claro",
  [constants.TXT_CLIENTS]: "clientes",
  [constants.TXT_CLIENT]: "cliente",
  [constants.TXT_CLOSED]: "cerrado",
  [constants.TXT_COMMENCED]: "comienzo",
  [constants.TXT_COMPLETED]: "completa",
  [constants.TXT_CONFIGURATIONS]: "configuraciones",
  [constants.TXT_COUNTERPARTIES]: "contrapartes",
  [constants.TXT_COUNTERPARTY]: "contraparte",
  [constants.TXT_SHIPOWNER_NOT_SET]: "Armador no ha sido seleccionado",
  [constants.TXT_COUNTERPARTY_TYPE]: "tipo de contraparte",
  [constants.TXT_COUNTERPARTY_TYPES]: "tipos de contraparte",
  [constants.TXT_CONNECT_TO_MICROSOFT_ACCOUNT]:
    "conectarse a la cuenta de microsoft",
  [constants.TXT_COUNT_AS_LAYTIME]: "contar como tiempo de descanso",
  [constants.TXT_COPY]: "copiar",
  [constants.TXT_CREATE_API_KEY]: "crear una clave de aPI",
  [constants.TXT_CREATE_BUSINESS_UNIT]: "crear una unidad de negocio",
  [constants.TXT_CREATE_COUNTERPARTY]: "crear una contraparte",
  [constants.TXT_OVERRIDE_LAYTIME_CALC_FINAL_VALUE]:
    "sobrescribir el valor final",
  [constants.TXT_OVERRIDE_LAYTIME_COMMENCED]:
    "sobrescribir fecha de inicio del laytime",
  [constants.TXT_UNDO_OVERRIDE_LAYTIME_CALC_FINAL_VALUE]:
    "revertir el valor final",
  [constants.TXT_CREATE_DEAL]: "crear un envío",
  [constants.TXT_CREATE_QUICK_CALCULATION]: "crear un cálculo rápido",
  [constants.TXT_CREATE_VESSEL]: "crear un recipiente",
  [constants.TXT_CREATE_TRADER]: "crear un comerciante",
  [constants.TXT_CURRENCY]: "divisa",
  [constants.TXT_DARK_THEME]: "tema oscuro",
  [constants.TXT_DASHBOARD]: "tablero",
  [constants.TXT_DAY]: "día",
  [constants.TXT_DEAL_INFO]: "información del envio",
  [constants.TXT_DESPATCH_DECIMAL_EQUIVALENT]: "despacho equivalente decimal",
  [constants.TXT_IGNORE]: "ignorar",
  [constants.TXT_DELETE]: "eliminar",
  [constants.TXT_DELETE_CONFIRMATION]: "borrar confirmación",
  [constants.TXT_DELETE_TEXT]: 'Estas seguro que quieres eliminar "{{name}}"?',
  [constants.TXT_CONFIRM_PASTE_PARAMS_TEXT]:
    "please confirm the info you want to Copy ?",
  [constants.TXT_LAYTIME]: "laytime",
  [constants.TXT_DEMURRAGE]: "demora",
  [constants.TXT_DEMURRAGE_DECIMAL_EQUIVALENT]:
    "equivalente decimal de estadía",
  [constants.TXT_DEMURRAGE_P_DAY]: "demora p/día",
  [constants.TXT_DEMURRAGE_IN_CURRENCY]: "Valor Final Demora ",
  [constants.TXT_DESCRIPTION]: "descripción",
  [constants.TXT_REMARKS]: "mensaje",
  [constants.TXT_ERRORS]: "errores",
  [constants.TXT_DESPATCH]: "envío",
  [constants.TXT_DESPATCH_IN_CURRENCY]: "Valor Final Despatch",
  [constants.TXT_DESPATCH_P_DAY]: "envío p/día",
  [constants.TXT_DISABLE]: "desactivar",
  [constants.TXT_DOCUMENT_USED]: "documento utilizado",
  [constants.TXT_DRAFT]: "borrador",
  [constants.TXT_EDIT]: "editar",
  [constants.TXT_EMAILS]: "correos electrónicos",
  [constants.TXT_EMAIL]: "correo electronico",
  [constants.TXT_ENABLE]: "activado",
  [constants.TXT_VALID_NOR]: "nor valido",
  [constants.TXT_ALWAYS_ON_DEMURRAGE]: "siempre en demora",
  [constants.TXT_NOT_ALWAYS_ON_DEMURRAGE]: "no siempre en demora",
  [constants.TXT_EVEN_IF_SOONER_COMMENCED]: "incluso si antes comenzó",
  [constants.TXT_FIELD_IS_REQUIRED]: "este campo es obligatorio",
  [constants.TXT_FREIGHT_PROVIDER]: "proveedor de flete",
  [constants.TXT_FULL_NAME]: "nombre completo",
  [constants.TXT_GENERATING_REPORT]: "generando informe",
  [constants.TXT_GOOGLE_ACCOUNT]: "cuenta Google",
  [constants.TXT_GO_TO_SIGN_IN_PAGE]: "ir a la página de inicio de sesión",
  [constants.TXT_INITIAL]: "iniciales",
  [constants.TXT_REFERENCE]: "referencia",
  [constants.TXT_INTEGRATIONS]: "integraciones",
  [constants.TXT_INTERRUPTIONS]: "interrupciones",
  [constants.TXT_INTERRUPTION_DEFINITIONS]: "definición de interrupciones",
  [constants.TXT_INTERRUPTION_DEFINITION]: "definición de interrupción",
  [constants.TXT_INVITATION_NOT_FOUND]:
    "el enlace de la invitación ha caducado o no es válido",
  [constants.TXT_INVITATION_SENT]: "la invitación se ha enviado a {{email}}",
  [constants.TXT_INVITE]: "invitación",
  [constants.TXT_INVITE_NEW_USERS]: "invitar a nuevos usuarios",
  [constants.TXT_INVITE_USERS]: "invitar usuarios",
  [constants.TXT_INVITING]: "incitante",
  [constants.TXT_INVOICED]: "facturado",
  [constants.TXT_LAYCAN]: "laycan",
  [constants.TXT_LAYTIME_CALCULATION]: "cálculo laytime",
  [constants.TXT_LAYTIME_CHART]: "Reporte de Laytime",
  [constants.TXT_LAYTIME_PER_COUNTERPARTY]: "Reporte de laytime por organizacion",
  [constants.TXT_SUPPLIER_VS_OWNER_REPORT]: "supplidor vs armador",
  [constants.TXT_LAYCAN_END]: "fin del laycan",
  [constants.TXT_LAYCAN_START]: "inicio del laycan",
  [constants.TXT_LESS_INTERRUPTIONS]: "menos interrupciones",
  [constants.TXT_LIGHT_THEME]: "tema de luz",
  [constants.TXT_LOADING]: "carga",
  [constants.TXT_UNLOADING]: "descarga",
  [constants.TXT_CHILD_DEAL]: "negociacione hijas",
  [constants.TXT_CHILD_DEALS]: "negociaciones hijas",
  [constants.TXT_VESSEL_OUTSIDE_LAYCAN]:
    "embarcacion esta por fuera del laycan",
  [constants.TXT_UNLOADING_COMMENCED]: "descarga iniciada",
  [constants.TXT_UNLOADING_COMPLETED]: "descarga completada",
  [constants.TXT_LOADING_PORT]: "puerto de carga",
  [constants.TXT_LOADING_PORTS]: "puertos de carga",
  [constants.TXT_LOADING_COMMENCED]: "comienzo de carga",
  [constants.TXT_LOADING_COMPLETED]: "carga completa",
  [constants.TXT_DISCHARGE_COMMENCED]: "comienzo de descarga",
  [constants.TXT_DISCHARGE_COMPLETED]: "fin de descarga",
  [constants.TXT_LOADING_RATE]: "tasa de carga",
  [constants.TXT_DISCHARGE_RATE]: "tasa de descarga",
  [constants.TXT_MICROSOFT_ACCOUNT]: "Cuenta de Microsoft",
  [constants.TXT_MASS_UNIT]: "unidad de masa",
  [constants.TXT_MV_ON_DEMURRAGE]: "mv sobre sobreestadía",
  [constants.TXT_NAME]: "nombre",
  [constants.TXT_NEGOTIATING]: "negociando",
  [constants.TXT_NOT_APPLICABLE]: "not applicable",
  [constants.TXT_CODE]: "codigo",
  [constants.TXT_CAPACITY]: "capacidad",
  [constants.TXT_NET_TIME_USED]: "tiempo neto utilizado",
  [constants.TXT_NOT_ACCEPTED]: "no aceptada",
  [constants.TXT_NOT_COUNT_AS_LAYTIME]: "no cuenta como tiempo de descanso",
  [constants.TXT_NO]: "no",
  [constants.TXT_NOR_TENDERED]: "notificacion de alistamiento radicada",
  [constants.TXT_NO_DATA_FOUND]: "datos no encontrados",
  [constants.TXT_OR]: "o",
  [constants.TXT_PASSWORD]: "contraseña",
  [constants.TXT_PASTE]: "pegar",
  [constants.TXT_PERMITTED_TIME]: "tiempo permitido",
  [constants.TXT_PORT]: "puerto",
  [constants.TXT_PRODUCT]: "producto",
  [constants.TXT_PROCESSED]: "procesado",
  [constants.TXT_QUANTITY]: "cantidad",
  [constants.TXT_SHARED_PERCENTAGE]: "shared percentage",
  [constants.TXT_REAL_LT_BEGINS]: "laytime comienza",
  [constants.TXT_LAYTIME_ENDS]: "laytime termina",
  [constants.TXT_PERMITTED_TIME_ROUNDING]: "Redondeo al tiempo permitido",
  [constants.TXT_SUPPLIER_CONTRIBUTION]: "contribución de proveedor",
  [constants.TXT_SUPPLIER_CONTRIBUTION_ERROR]:
    "contribución de proveedores no suma 100%",
  [constants.TXT_MODIFYING_LOCKED_CALC_VALIDATION]:
    "Esta modificando un calculo en el que la figura final esta bloqueada.",
  [constants.TXT_RECEIVERS]: "receptores",
  [constants.TXT_RECEIVER]: "receptor",
  [constants.TXT_REGISTERING]: "registrando",
  [constants.TXT_REGISTER]: "registrarse",
  [constants.TXT_REGISTER_A_NEW_TENANT]: "registrar un nuevo inquilino",
  [constants.TXT_REGISTER_THE_FIRST_USER]: "registrar el primer usuario",
  [constants.TXT_REJECTED]: "rechazado",
  [constants.TXT_RETYPE_PASSWORD]: "vuelva a escribir la contraseña",
  [constants.TXT_REPORTS]: "informes",
  [constants.TXT_ROWS_PER_PAGE]: "filas por página",
  [constants.TXT_SALES_CONTRACT]: "contrato de venta",
  [constants.TXT_SAVE]: "salvar",
  [constants.TXT_SAVE_AND_CLOSE]: "save & close",
  [constants.TXT_SAVING]: "salvando",
  [constants.TXT_INTERRUPTION_TOO_BIG]:
    "intervalo de tiempo dura 48 horas o mas",
  [constants.TXT_INTERRUPTION_HAS_DATES_IN_FUTURE]:
    "fechas futuras no son permitidas",
  [constants.TXT_SEARCH]: "búsqueda",
  [constants.TXT_SEARCH_BY_NAME]: "buscar por nombre",
  [constants.TXT_SEARCH_BY_USERNAME_NAME_INITIAL]:
    "buscar por nombre de usuario o nombre o inicial o correo electrónico",
  [constants.TXT_DEALS]: "envios",
  [constants.TXT_QUICK_CALCS]: "cotizaciones",
  [constants.TXT_QUICK_CALC]: "cotizacion",
  [constants.TXT_DEAL]: "envío",
  [constants.TXT_DEAL_NUMBER]: "numero de negociacion",
  [constants.TXT_PARENT_NUMBER]: "envio padre",
  [constants.TXT_TXT_VOYAGE_NAME]: "nombre embarcacion",
  [constants.TXT_SHIPOWNER]: "armador",
  [constants.TXT_SIGNING_IN]: "iniciando sesión",
  [constants.TXT_SIGNING_OUT]: "cerrando sesión",
  [constants.TXT_SIGN_IN]: "iniciar sesión",
  [constants.TXT_SIGN_OUT]: "desconectar",
  [constants.TXT_SINCE]: "ya que",
  [constants.TXT_STATUS]: "estado",
  [constants.TXT_STATUS_COMMENT]: "comentario",
  [constants.TXT_SUBMIT]: "enviar",
  [constants.TXT_SUPPLIER]: "proveedor",
  [constants.TXT_SUPPLIERS]: "proveedores",
  [constants.TXT_SPECIAL_CONDITION]: "condición especial",
  [constants.TXT_ONCEONDEMURRAGE_CONDITION]: "una vez en demora...",
  [constants.TXT_TERM]: "término",
  [constants.TXT_TIME_IN_DESPATCH]: "tiempo en despacho",
  [constants.TXT_TIME_IN_DEMURRAGE]: "tiempo de demora",
  [constants.TXT_TOTAL_TIME_USED]: "tiempo total utilizado",
  [constants.TXT_TRAFFIC_ANALYST]: "analista de tráfico",
  [constants.TXT_TO]: "para",
  [constants.TXT_UNTIL]: "hasta",
  [constants.TXT_FROM]: "desde",
  [constants.TXT_TURN_TIME]: "convertir el tiempo",
  [constants.TXT_TYPE]: "tipo",
  [constants.TXT_VESSEL_TYPE]: "tipo de embarcacion",
  [constants.TXT_TRADER]: "comerciante",
  [constants.TXT_TRADERS]: "comerciantes",
  [constants.TXT_UNLOADING_PORT]: "puerto de descarga",
  [constants.TXT_UNLOADING_PORTS]: "puertos de descarga",
  [constants.TXT_UNLESS_SOONER_COMMENCED]: "a menos que comience antes",
  [constants.TXT_UPDATE_BUSINESS_UNIT]: "actualizar unidad de negocio",
  [constants.TXT_UPDATE_COUNTERPARTY]: "actualizar contraparte",
  [constants.TXT_UPDATE_DEAL]: "actualizar un negociacion",
  [constants.TXT_UPDATE_QUICK_CALC]: "modificar una cotizacion",
  [constants.TXT_BATCH_OVERRIDE_TITLE]: "seleccione los calculos a sobreescribir",
  [constants.TXT_UPDATE_VESSEL]: "buque de actualización",
  [constants.TXT_UPDATE_TRADER]: "actualizar comerciante",
  [constants.TXT_USERS]: "usuarios",
  [constants.TXT_USER]: "usuario",
  [constants.TXT_VESSELS]: "embarcaciones",
  [constants.TXT_VESSEL]: "embarcación",
  [constants.TXT_LAKER]: "laker",
  [constants.TXT_IMO]: 'OMI',
  [constants.TXT_VESSEL_INFORMATION]: "información del barco",
  [constants.TXT_WELCOME]: "bienvenido, {{name}}",
  [constants.TXT_WITH]: "con",
  [constants.TXT_X_TO_DISCOUNT]: "(x) descontar",
  [constants.TXT_YES]: "sí",
  [constants.TXT_PORTS]: "puertos",
  [constants.TXT_PRODUCTS]: "productos",
  [constants.TXT_MASS_UOM_OVERRIDE]: "UOM override",
  [constants.TXT_COUNTRY]: "país",
  [constants.TXT_CREATE_PORT]: "crear puerto",
  [constants.TXT_UPDATE_PORT]: "modificar puerto",
  [constants.TXT_CREATE_PRODUCT]: "crear producto",
  [constants.TXT_UPDATE_PRODUCT]: "modificar producto",
  [constants.TXT_CREATING_LAYTIME_CALCULATION]: "creando laytime calculation",
  [constants.TXT_ADD_LAYTIME_CALCULATION]: "agregar laytime calculation",
  [constants.TXT_ADD_HOLIDAY]: "agregar festivo",
  [constants.TXT_OLD_VALUE]: "valor actual",
  [constants.TXT_NEW_VALUE]: "new value",
  [constants.TXT_PREVIOUS]: "previo",
  [constants.TXT_NEXT]: "próximo",
  [constants.TXT_VESSEL_INFORMATION_OVERRIDE]:
    "sobrescribir de la información del buque",
  [constants.TXT_FIELD]: "campo",
  [constants.TXT_CHANGE_CALCULATION_STATUS]: "cambiar estado de cálculo",
  [constants.TXT_OVERRIDE_ALL_CALCULATIONS]: "sobrescribir todos los cálculos",
  [constants.TXT_BACK_TO_SIGN_IN_PAGE]:
    "volver a la página de inicio de sesión",
  [constants.TXT_UPDATE_USER]: "actualizar usuario",
  [constants.TXT_UPDATE_MY_PROFILE]: "actualizar mi perfil",
  [constants.TXT_SELECTED_ITEMS]: "{{count}} seleccionado",
  [constants.TXT_PORT_TYPE]: "tipo de puerto",
  [constants.TXT_DATE]: "fecha",
  [constants.TXT_TIME]: "tiempo",
  [constants.TXT_ADD_INTERRUPTION]: "añadir interrupción",
  [constants.TXT_ADD_RECURRENT_SHEX]: "agregar shex recurrente",
  [constants.TXT_LINK_HAS_BEEN_COPIED_TO_CLIPBOARD]:
    "el enlace ha sido copiado al portapapeles",
  [constants.TXT_CLICK_HERE_TO_OPEN]: "Haga clic <0>aquí</0> para abrir",
  [constants.TXT_CREATE_SHAREABLELINK]: "crear enlace para compartir",
  [constants.TXT_UPDATE_SHAREABLELINK]: "actualizar enlace para compartir",
  [constants.TXT_LINK_ID]: "identificación del enlace",
  [constants.TXT_DOWNLOAD_LAYTIME_CHART]:
    "descargar gráfico de tiempo de reproducción",
  [constants.TXT_DOWNLOAD]: "descargar",
  [constants.TXT_DOWNLOADING]: "descargando",
  [constants.TXT_LINKED_ACCOUNTS]: "cuentas vinculadas",
  [constants.TXT_USER_PROFILE]: "perfil del usuario",
  [constants.TXT_CHANGE_PASSWORD]: "cambia la contraseña",
  [constants.TXT_USER_PROFILE_UPDATED_SUCCESSFULLY]: "perfil de usuario actualizado con éxito",
  [constants.TXT_LAYTIME_CHART_REPORT_FILTER]: "Pamaetros del reporte de laytime",
  [constants.TXT_LAYTIME_PER_COUNTERPARTY_REPORT_FILTER]: "parametros del reporte de laytime por organizacion",
  [constants.TXT_SUPPLIER_VS_OWNER_REPORT_FILTER] : "Parametros del report Suplidor vs armador",
  [constants.TXT_PROFIT_AND_LOSS]: "ganancias y perdidas",
  [constants.TXT_VOYAGE_PROFIT_AND_LOSS]:
    "ganancias y perdidas por embarcacion",
  [constants.TXT_DEAL_PROFIT_AND_LOSS]: "ganancias y perdidas por envio",
  [constants.TXT_TRAFFIC_SPECIALIST]: "especialista en tráfico",
  [constants.TXT_SETTLE_STATUS]: "liquidar estado",
  [constants.TXT_NET]: "neto",
  [constants.TXT_PROFIT]: "lucro",
  [constants.TXT_LOSS]: "pérdida",
  [constants.TXT_COMPANY]: "empresa",
  [constants.TXT_DISCHARGE_COUNTRY]: "país de descarga",
  [constants.TXT_DISCHARGE_PORT]: "puerto de descarga",
  [constants.TXT_REPORT_COPIED]:
    "el contenido del informe se ha copiado en el portapapeles",
  [constants.TXT_EXCHANGE_RATE]: "tipo de cambio",
  [constants.TXT_BARGE]: "barcaza",
  [constants.TXT_BARGES]: "barcazas",
  [constants.TXT_HELP]: "ayuda",
  [constants.TXT_CREATE_BARGE]: "crear barcaza",
  [constants.TXT_UPDATE_BARGE]: "actualizar barcaza",
  [constants.TXT_BARGE_TRIPS]: "viajes en barcaza",
  [constants.TXT_CREATE_BARGE_TRIP]: "crear viaje en barcaza",
  [constants.TXT_UPDATE_BARGE_TRIP]: "actualizar viaje en barcaza",
  [constants.TXT_ASSOCIATED_DEAL_NUMBER]: "número de operación asociado",
  [constants.TXT_DUPLICATING_BARGE]: "duplicando la barcaza...",
  [constants.TXT_ARRIVAL_DATE]: "fecha de llegada",
  [constants.TXT_ARRIVAL_DATE_OVERRIDE]: "sobrescribir fecha de llegada",
  [constants.TXT_COMPLETION_DATE]: "fecha de finalización",
  [constants.TXT_COMPLETION_DATE_OVERRIDE]:
    "sobrescribir fecha de finalización",
  [constants.TXT_RELEASE_DATE]: "fecha liberacion",
  [constants.TXT_RELEASE_DATE_OVERRIDE]: "sobrescribir fecha liberacion",
  [constants.TXT_AND_MORE]: "y {{count}} más",
  [constants.TXT_FREE_TIME]: "tiempo libre",
  [constants.TXT_DAYS]: "días",
  [constants.TXT_HOURS]: "horas",
  [constants.TXT_MINUTES]: "minutos",
  [constants.TXT_TOTAL_DEMURRAGE]: "sobreestadía total",
  [constants.TXT_OWNER]: "armador",
  [constants.TXT_BARGE_TRIP_INFO]: "información sobre el viaje en barcaza",
  [constants.TXT_TOTAL]: "total",
  [constants.TXT_CALENDAR_RULE]: "norma de calendario",
  [constants.TXT_HOLIDAYS]: "vacaciones",
  [constants.TXT_CALENDAR]: "calendario",
  [constants.TXT_YEAR]: "año",
  [constants.TXT_EN_US]: "US",
  [constants.TXT_ES_ES]: "España",
  [constants.TXT_NO_ROWS]: "sin registros",
  [constants.TXT_UPDATE]: "actualizar",
  [constants.TXT_CREATE_HOLIDAY]: "crear vacaciones",
  [constants.TXT_UPDATE_HOLIDAY]: "actualizar vacaciones",
  [constants.TXT_HOLIDAY]: "vacaciones",
  [constants.TXT_BARGE_REPORT_FILTER]: "filtro de informes de barcazas",
  [constants.TXT_FORGOT_PASSWORD]: "olvidó su contraseña",
  [constants.TXT_FORGOT_PASSWORD_TITLE]: "¿olvidaste tu contraseña?",
  [constants.TXT_FORGOT_PASSWORD_TEXT]:
    "introduzca su dirección de correo electrónico y le enviaremos las instrucciones para restablecer su contraseña",
  [constants.TXT_VERICIATION_CODE_TITLE]: "introduce el código de verificación",
  [constants.TXT_VERICIATION_CODE]: "código de verificación",
  [constants.TXT_VERICIATION_CODE_TEXT]:
    "para verificar tu cuenta, necesitas confirmar tu cuenta Atreus. Por favor, introduzca el código de verificación que le hemos enviado",
  [constants.TXT_VERIFY]: "verificar",
  [constants.TXT_ENTER_NEW_PASSWORD]:
    "introduzca una nueva contraseña para acceder a su cuenta",
  [constants.TXT_BARGE_LAYTIME_PER_COUNTERPARTY]:
    "barcaza laytime por organizacion",
  [constants.TXT_OCR]: "ocr (próximamente)",
  [constants.TXT_UPLOADING]: "uploading",
  [constants.TXT_NUMBER]: "número",
  [constants.TXT_TEXT]: "texto",
  [constants.TXT_RESULT]: "resultado",
  [constants.TXT_DRAG_N_DROP_TEXT]:
    "arrastre & suelte algunos archivos aquí, o haga clic para seleccionar archivos",
  [constants.TXT_FILE_RESTRICTION]: "sólo se aceptarán imágenes y pdf",
  [constants.TXT_EDIT_CURRENT_DEAL]: "editar envío actual",
  [constants.TXT_COMMENTS]: "comentarios",
  [constants.TXT_TYPE_COMMENT_HERE]: "escriba aquí su comentario",
  [constants.TXT_TYPE_REPLY_HERE]: "escriba aquí la respuesta",
  [constants.TXT_PRINT]: "imprimir",
  [constants.TXT_RESOLVE]: "resolver",
  [constants.TXT_RESOLVED]: "resuelto",
  [constants.TXT_SEND]: "enviar",
  [constants.TXT_UNRESOLVE]: "sin resolver",
  [constants.TXT_DELETE_COMMENT_WITH_REPLIES]:
    "¿estás seguro de que quieres borrar este comentario incluyendo todas las respuestas?",
  [constants.TXT_DELETE_COMMENT]:
    "¿estás seguro de que quieres borrar este comentario?",
  [constants.TXT_QUICK_CALC_INFO]: "información de cálculo rápido",
  [constants.TXT_DETAILS]: "detalles",
  [constants.TXT_CLOSE]: "cerrar",
  [constants.TXT_VERSION]: "versión",
  [constants.TXT_BACK_TO_PARENT]: "volver a los padres",
  [constants.TXT_GO_TO]: "ir a",
  [constants.TXT_PROMOTE]: "promover",
  [constants.TXT_RESET]: "resetear",
  [constants.TXT_CONFIRMATION]: "confirmación",
  [constants.TXT_PROMOTE_CALCULATION_CONFIRMATION]:
    "está seguro de querer promover este cálculo?",
  [constants.TXT_RESET_CALCULATION_CONFIRMATION]:
    "está seguro de que desea restablecer este cálculo?",
  [constants.TXT_WHAT_IF_SCENARIOS]: "Escenarios Hipotéticos",
  [constants.TXT_CREATE_WHAT_IF_SCENARIOS]: "Crear Escenario Hipotético",
  [constants.TXT_UPDATE_WHAT_IF_SCENARIOS]:
    "Actualización de Escenario Hipotético",
  [constants.TXT_OPEN_WHAT_IF_SCENARIO]: "Escenario Escenario Hipotético",
  [constants.TXT_MARITIME_DIRECTORIES]: "directorios marítimos",
  [constants.TXT_CREATE_MARITIME_DIRECTORY]: "crear directorio marítimo",
  [constants.TXT_UPDATE_MARITIME_DIRECTORY]:
    "actualización del directorio marítimo",
  [constants.TXT_DELETE_MARITIME_DIRECTORY]:
    "está seguro de que desea eliminar este contacto?",
  [constants.TXT_ADDRESSES]: "direcciones",
  [constants.TXT_PHONES]: "teléfonos",
  [constants.TXT_ADDRESS]: "dirección",
  [constants.TXT_PHONE]: "teléfono",
  [constants.TXT_COPIED_TO_CLIPBOARD]: "copiado en el portapapeles",
  [constants.TXT_FAILED_TO_COPY_TO_CLIPBOARD]:
    "fallo al copiar al portapapeles",
  [constants.TXT_CONTACT]: "contacto",
  [constants.TXT_AGENT]: "agente",
  [constants.TXT_VESSEL_PORTAL]: "portal de buque",
  [constants.TXT_LATITUDE]: "latitud",
  [constants.TXT_LONGITUDE]: "longitud",
  [constants.TXT_SEND_REQUEST_FORM_TO_AGENT]:
    "enviar formulario de solicitud al agente",
  [constants.TXT_REQUEST_FORM]: "formulario de solicitud",
  [constants.TXT_UPDATE_PORT_AGENT]: "actualizar el agente del puerto",
  [constants.TXT_PORT_INFORMATION]: "información portuaria",
  [constants.TXT_SAVED]: "guardado",
  [constants.TXT_EMAIL_HAS_BEEN_SENT]:
    "el correo electrónico ha sido enviado a {{email}}",
  [constants.TXT_SEND_EMAIL]: "enviar correo electrónico",
  [constants.TXT_SENDING_EMAIL]: "enviar correo electrónico a {{email}}",
  [constants.TXT_CREATING_SHAREABLELINK]: "crear enlace compartible",
  [constants.TXT_SUBMITTING]: "enviando",
  [constants.TXT_SUBMITTED]: "enviado",
  [constants.TXT_DEVELOPED_BY]: "desarrollado por",
  [constants.TXT_REQUESTED_BY]: "solicitado por",
  [constants.TXT_RESPONDED]: "respondió",
  [constants.TXT_GRANT_ACCESS]: "conceder acceso",
  [constants.TXT_COPY_LINK]: "copiar enlace",
  [constants.TXT_DELETE_SHAREABLE_LINK]:
    "estás seguro de que quieres borrar este enlace?",
  [constants.TXT_TONNAGE_ON_BOARD]: "tonelaje a bordo",
  [constants.TXT_TONNAGE_PENDING]: "tonelaje pendiente",
  [constants.TXT_EMAIL_ADDRESS]: "correo electrónico",
  [constants.TXT_SEARCH_VESSEL]: "Buscar buque por nombre u OMI",
  [constants.TXT_NO_VESSEL_FOUND]: "no se ha encontrado ningún buque",
  [constants.TXT_NO_DEAL_FOUND]: "no se ha encontrado ningún envío",
  [constants.TXT_SEARCH_DEAL]: "buscar envío",
  [constants.TXT_COURSE]: "rumbo",
  [constants.TXT_SPEED]: "velocidad",
  [constants.TXT_DRAUGHT]: "calado del buque",
  [constants.TXT_CALLSIGN]: "indicativo",
  [constants.TXT_LENGTH]: "longitud",
  [constants.TXT_BEAM]: "viga",
  [constants.TXT_CROSSINGS]: "cruces",
  [constants.TXT_DISTANCE]: "distancia",
  [constants.TXT_VESSEL_TRACKER]: "rastreador de buques",
  [constants.TXT_PORT_INFORMATION_UPDATED]:
    "la información del puerto {{name}} ha sido actualizada",
  [constants.TXT_ETA_OUTSIDE_LAYCAN]: "ETA está fuera de laycan",
  [constants.TXT_STATEMENT_OF_FACT]: 'exposición de los hechos',
  [constants.TXT_DESTINATION]: 'destinación',
  [constants.TXT_VALUE]: 'valor',
  [constants.TXT_NOW]: 'ahora',
  [constants.TXT_OK]: 'ok',
  [constants.TXT_OCR_RESULT]: 'resultado del ocr',
  [constants.TXT_OCR_SCAN]: 'escaneado ocr',
  [constants.TXT_SPLIT]: 'divida',
  [constants.TXT_MERGE]: 'fusionar',
  [constants.TXT_OPERATOR]: 'operadores',
  [constants.TXT_GUEST]: 'Invitado',
  [constants.TXT_ROLE]: 'rol',
  [constants.TXT_PLEASE_SELECT_A_VESSEL]: 'seleccione un buque',
  [constants.TXT_SEARCH_BY_SHIPMENT_DEAL_VESSEL]: 'Búsqueda por nombre de envío, número de envío, nombre de buque u OMI del buque',
  [constants.TXT_CURRENT]: 'actual',
  [constants.TXT_DIFFERENCE]: 'diferencia',
  [constants.TXT_REFRESH]: 'actualizar',
  [constants.TXT_LAST_RESPOND]: 'última respuesta',
  [constants.TXT_EDIT_VESSEL]: 'buque de edición',
  [constants.TXT_HISTORY]: 'historia',
  [constants.TXT_LOAD_MORE]: 'cargar más',
  [constants.TXT_LAT_LONG_EXAMPLE]: '{{portName}} se encuentra en {{country}} en {{latC}}, {{longC}}. Por lo tanto, la latitud es {{lat}} y la longitud es {{long}}.',
  [constants.TXT_UPDATE_PORT_INFO]: 'actualizar información del puerto',
  [constants.TXT_SUBMIT_AND_COMPLETE]: 'presentar y completar',
  [constants.TXT_DEAL_PORT_FORM_SUBMIT_AND_COMPLETE_CONFIRMATION]: 'está seguro de que desea enviar y rellenar este formulario?',
  [constants.TXT_DEAL_PORT_FORM_SUBMIT_AND_COMPLETE_WARNING]: 'no podrá acceder a este enlace en el futuro',
  [constants.TXT_COMPLETE]: 'completa',
  [constants.TXT_SENDING]: 'enviando',
  [constants.TXT_MESSAGE]: 'mensaje',
  [constants.TXT_TYPE_MESSAGE_HERE]: 'escriba aquí el mensaje',
  [constants.TXT_MESSAGES]: 'mensajes',
  [constants.TXT_MERGE_PORTS]: 'fusionar puertos',
  [constants.TXT_DATA_MAINTENANCE]: 'mantenimiento de datos',
  [constants.TXT_SOURCE_PORTS]: 'puertos de origen',
  [constants.TXT_DESTINATION_PORT]: 'puerto de destino',
  [constants.TXT_TEST]: 'prueba',
  [constants.TXT_APPLY]: 'aplicar',
  [constants.TXT_LOG]: 'registro',
  [constants.TXT_STARTED_AT]: 'comenzó en',
  [constants.TXT_FINISHED_AT]: 'terminado en',
  [constants.TXT_DURATION]: 'duración',
  [constants.TXT_WEB_FORMS]: 'formularios web',
  [constants.TXT_READY_TO_SYNC]: 'listo para sincronizar',
  [constants.TXT_SHIPMENT]: 'envío',
  [constants.TXT_LAST_SEARCHED]: 'última búsqueda',
  [constants.TXT_SHOW_ON_MAP]: 'mostrar en el mapa',
  [constants.TXT_CHANGE_COORDINATES_CONFIRMATION]: 'confirmación de cambio de coordenadas',
  [constants.TXT_CHANGE_COORDINATES_CONFIRMATION_TEXT]: 'desea utilizar estas coordenadas latitud = {{latitude}}, longitud = {{longitude}}?',

  [constants.ERR_INVALID_TENANT]: "inquilino inválido",
  [constants.ERR_UNKNOWN_ERROR]: "error desconocido",
  [constants.ERR_INVALID_SENDER_EMAIL]:
    "correo electrónico del remitente no válido",
  [constants.ERR_INVALID_RECIPIENT_EMAIL]:
    "correo electrónico del destinatario no válido",
  [constants.ERR_TENANT_ALIAS_EXISTED]:
    "el inquilino con el mismo alias ha existido",
  [constants.ERR_USER_NOT_FOUND]: "usuario no encontrado",
  [constants.ERR_USER_DISABLED]:
    "este usuario fue inhabilitado, comuníquese con su administrador",
  [constants.ERR_USERNAME_EXISTED]:
    "el nombre de usuario ha sido tomado por otro usuario",
  [constants.ERR_EMAIL_EXISTED]:
    "el correo electrónico ha sido utilizado por otro usuario",
  [constants.ERR_USER_INITIAL_EXISTED]:
    "la inicial ha sido utilizada por otro usuario.",
  [constants.ERR_MICROSOFT_ACCOUNT_EXISTED]:
    "esta cuenta de Microsoft se ha vinculado a otro usuario",
  [constants.ERR_INVALID_EMAILS]:
    "hay una o más direcciones de correo electrónico no válidas",
  [constants.ERR_WRONG_PASSWORD]: "contraseña incorrecta",
  [constants.ERR_INVALID_SPLIT_QUANTITY_NAME]:
    "número de operación asociado no válido",
  [constants.ERR_IMO_NOT_DEFINED]: "IMO no definido",
  [constants.ERR_PORT_NO_COORDINATES]: "el puerto no puede situarse en el mapa por falta de coordenadas",
  [constants.ERR_LOADING_PORT_RULES]: "el viaje del buque debe tener al menos un puerto con fecha de conocimiento de embarque fijada",
  [constants.ERR_DEAL_NAME_NOT_MATCH_PATTERN]: "el nombre del acuerdo no coincide con el patrón, ejemplo de entrada correcta {{ejemplo}}",
};
